import { IconButton, TextField } from "@mui/material";
import "../styles/search.css";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const Search = ({ setAddedProducts, addedProducts, products }) => {
  const [searchQuery, setQueryState] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const onSearch = () => {
    if (searchQuery?.length > 0) {
      const results = products.filter((p) => {
        return (
          p.No?.toString()?.includes(searchQuery.toLowerCase()) ||
          p.Name?.toLowerCase()?.includes(searchQuery.toLowerCase())
        );
      });
      setSearchResults(results);
    }
  };

  const handleAdd = (e) => {
    setAddedProducts((p) => [{ ...e, prints: 1 }, ...p]);
    //console.log(e);
  };

  const handleRemove = (e) => {
    setAddedProducts((p) => p.filter((o) => o.Id !== e.Id));
  };

  return (
    <div className="searchWrapper">
      <TextField
        sx={{ marginBottom: "2em", width: "100%" }}
        id="standard-basic"
        label="Søk for produkt"
        variant="standard"
        onChange={(e) => setQueryState(e.target.value)}
        onKeyDown={(i) => {
          if (i.key === "Enter") {
            onSearch();
          }
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              tabIndex={-1}
              aria-label="add"
              size="small"
              onClick={() => onSearch()}
              sx={{
                width: "30px",
                height: "30px",
                margin: "5px",
                color: "#999",
                backgroundColor: "#eee",
                transitionDuration: "300ms",
                "&:hover": {
                  backgroundColor: "var(--addedColor-b)",
                  color: "white",
                },
              }}
            >
              <SearchIcon />
            </IconButton>
          ),
        }}
      />

      <div className="searchContent">
        <div className="searchGrid">
          {searchResults.map((e, index) => {
            const isAdded = addedProducts.some((p) => p.Id === e.Id);
            return (
              <div
                key={index}
                className={`searchItem${isAdded ? " added" : ""}`}
              >
                <h1>{e?.Name}</h1>
                <div className="infobox">
                  <h3>No: {e.No}</h3>
                </div>
                <IconButton
                  tabIndex={-1}
                  aria-label="add"
                  size="small"
                  onClick={() => (!isAdded ? handleAdd(e) : handleRemove(e))}
                >
                  {!isAdded ? "+" : "-"}
                </IconButton>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Search;
