import PrintLabel from "../main/blocks/PrintLabel.jsx";
import { renderToString } from "react-dom/server";

const printLabel = (pList) => {
  var printer = window.open("", "", "width=950,height=680");
  printer.document.write('<div class="LabelContainer">');
  var html = pList
    .map((p) => {
      var amount = "";
      for (var i = 0; i < p.prints; i++) {
        amount = amount + renderToString(<PrintLabel product={p} />);
      }
      return amount;
    })
    .join("");
  printer.document.write(html);
  printer.document.write("</div>");
  loadStyle(printer, "Label.css", function () {
    printer.document.close();
    printer.focus();
    printer.print();
    printer.close();
  });
};

function loadStyle(w, href, callback) {
  // avoid duplicates
  for (var i = 0; i < w.document.styleSheets.length; i++) {
    if (w.document.styleSheets[i].href === href) {
      return;
    }
  }
  var head = w.document.getElementsByTagName("head")[0];
  var link = w.document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = href;
  link.media = "print";
  if (callback) {
    link.onload = function () {
      callback();
    };
  }
  head.appendChild(link);
}

export default printLabel;
