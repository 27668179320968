import { CircularProgress } from "@mui/material";
import React from "react";
import "../styles/loading.css";

const Loading = ({ text }) => {
  return (
    <div className="loading-wrapper">
      <CircularProgress color="inherit" />
      <p className="info-text">{text}</p>
      <div className="webhook">
        <span>Powered by</span>
        <svg
          id="Layer_1"
          fill="#122747"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 499.99"
        >
          <path d="m475.99,122.64c-18.06-17.56-35.72-35.55-53.48-53.4-1.46-1.47-2.5-3.35-3.73-5.05-.52.38-1.05.76-1.56,1.14,3.49,11.18,6.95,22.38,10.5,33.55,4.68,14.78,9.81,29.43,14.02,44.33,1.97,7.02,7.1,11.01,11.76,15.52,3.4,3.32,4.72,6.75,4.71,11.5-.15,53.23-.03,106.47-.25,159.71-.01,3.21-1.27,7.31-3.44,9.5-34.77,35.16-69.79,70.07-104.77,105.02-.77.77-1.66,1.44-3.4,1.71,3.17-10.15,6.3-20.31,9.52-30.44,15.97-50.27,31.91-100.55,47.97-150.79,3.24-10.1,3.26-19.99.05-30.09-18.92-59.44-37.76-118.91-56.64-178.37-2.51-7.92-5.07-15.83-7.6-23.75C332.98,11.99,316.69.03,295.01.03,252.19.02,209.38.07,166.56,0c-13.38-.02-24.67,4.48-34.18,14.02C93.06,53.46,53.68,92.85,14.22,132.17,4.71,141.64-.01,152.86,0,166.25.05,221.95.05,277.65,0,333.35c-.02,13.39,4.65,24.63,14.17,34.11,36.5,36.37,73.04,72.71,109.26,109.37,10.52,10.66,21.09,20.63,36.59,23.16h179.65c15.72-2.51,26.3-12.75,36.92-23.52,33.07-33.52,66.36-66.82,99.87-99.88,10.77-10.62,21-21.22,23.53-36.92v-179.65c-2.52-15.99-13.04-26.69-24.01-37.37ZM198.94,47.33c1.37-4.35,3.37-5.89,8-5.86,28.79.21,57.59.17,86.37.01,4.13-.02,6.04,1.37,7.28,5.29,18.76,59.5,37.67,118.95,56.53,178.41.28.88.51,1.77.93,3.27h-216.59l57.47-181.13ZM51.67,153.09c32.29-31.43,63.9-63.55,95.77-95.4,1.58-1.58,3.24-3.09,6.05-5.78-.73,3.16-1,4.85-1.52,6.47-17.51,55.1-35.07,110.18-52.49,165.31-1.2,3.8-2.76,5.48-7.04,5.4-15.13-.27-30.28-.09-45.43-.13-1.66,0-3.33-.23-5.41-.39,0-17.02.63-33.69-.23-50.28-.56-10.53,2.72-17.84,10.3-25.21Zm97.03,290.13c-34.28-34.27-68.52-68.57-102.87-102.76-3.05-3.04-4.36-6.18-4.32-10.5.18-19.48.08-38.96.08-59.26,18.27,0,35.67-.14,53.05.23,1.61.03,3.95,3,4.62,5.03,13.24,41.19,26.3,82.44,39.4,123.68,4.9,15.44,9.8,30.88,13.79,47.09-1.25-1.16-2.54-2.29-3.75-3.5Zm152.39,8.27c-1.55,4.92-3.77,6.78-9.16,6.72-28.41-.3-56.82-.19-85.23-.08-3.87.01-6.11-.78-7.44-4.99-18.8-59.67-37.81-119.28-56.77-178.9-.27-.85-.44-1.74-.8-3.13h216.52c-4.54,14.35-42.62,134.21-57.12,180.38Z" />
        </svg>
        <span>Webhook</span>
      </div>
    </div>
  );
};

export default Loading;
