import React, { useEffect, useState } from "react";
import { getProducts, getCategories } from "../../api/api";
import Loading from "./Loading";
import Portal from "./Portal";

const PortalPage = ({ session }) => {
  const [products, setProducts] = useState([]);
  const [fetchingState, setFetchingState] = useState(true);

  useEffect(() => {
    async function fetchCategories() {
      const response = await getCategories(session);
      const { categories: fetchedCategories } = response;
      if (!fetchedCategories) {
        console.error("Failed to fetch products!", response);
      } else {
        //console.log(fetchedCategories);

        Promise.all(
          fetchedCategories.map(async (c) => {
            const { products } = await getProducts(c, session);
            if (!!products && Array.isArray(products)) {
              setProducts((p) => [...p, ...products]);
              return products.length;
            }
            return null;
          })
        ).then(() => {
          setFetchingState(false);
        });
      }
    }
    fetchCategories();
  }, []);

  return (
    <>
      {fetchingState ? (
        <Loading text={`Henter produkter`} />
      ) : (
        <Portal products={products} />
      )}
    </>
  );
};

export default PortalPage;
