async function postWithBody(url, body) {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw response;
  }

  const data = await response.json();
  return data;
}

async function login(body) {
  return await postWithBody("api/auth", body)
}

async function getCategories(sessionId) {
  return await postWithBody("api/categories", { sessionId });
}

async function getProducts(category, sessionId) {
  return await postWithBody("api/products", { categoryId: category.Id, sessionId });
}

export { login, getProducts, getCategories };