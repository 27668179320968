import "./main/styles/App.css";
import Portal from "./main/sections/Portal";
import { useState } from "react";
import Login from "./main/sections/Login";
import PortalPage from "./main/sections/PortalPage";

function App() {
  const [session, setSession] = useState(null);

  return (
    <div className="App">
      {!session ? (
        <Login setSession={setSession} />
      ) : (
        <PortalPage session={session} />
      )}
    </div>
  );
}

export default App;
