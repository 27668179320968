import React from "react";

const Error = (e) => {
  return <span className="LabelError">{e} ikke funnet</span>;
};

const PrintLabel = ({ product }) => {
  return (
    <div className="Label">
      <span className="Name">{product.Name || Error("Navn")}</span>
      <span className="ProductNumber">{product.No || Error("No")}</span>
      <span className="SupplierProductCode">
        <span>Lev. varenr.:</span>
        <span>{product.SupplierProductCode || Error("Lev. No")}</span>
      </span>
      <span className="Location">
        <span className="location-text">
          Lokasjon: {product.StockLocation || Error("Lokasjon")}
        </span>
        <span className="date">{new Date().toLocaleDateString("nb-NO")}</span>
      </span>
    </div>
  );
};

export default PrintLabel;
