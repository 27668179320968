import {
  TextField,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  IconButton,
} from "@mui/material";
import "../styles/portal.css";
import DeleteIcon from "@mui/icons-material/Delete";
import lodash from "lodash";
import Search from "./Search";
import Printer from "../../api/printer";
import { useState } from "react";

const Portal = ({ products }) => {
  const [addedProducts, setAddedProducts] = useState([]);

  const handlePrintChange = (value, index) => {
    const changed = lodash.cloneDeep(addedProducts);
    changed[index].prints = value;
    setAddedProducts(changed);
  };

  const removeProduct = (index) => {
    setAddedProducts((e) => e.filter((_, i) => i !== index));
  };

  return (
    <>
      <Search
        setAddedProducts={setAddedProducts}
        addedProducts={addedProducts}
        products={products}
      />
      <div className="portalWrapper">
        <div className="header">
          <h1>Utskriftskø</h1>{" "}
          <div className="buttons">
            <Button
              variant="contained"
              onClick={() => Printer(addedProducts)}
              disabled={addedProducts.length ? false : true}
            >
              Print
            </Button>
            <Button
              onClick={() =>
                window.confirm("Er du sikker på at du vil tømme køen?")
                  ? setAddedProducts([])
                  : null
              }
              color="error"
              startIcon={<DeleteIcon />}
              disabled={addedProducts.length ? false : true}
            >
              Tøm kø
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Navn</TableCell>
                <TableCell align="center">Innstillinger</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addedProducts.map((e, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{e.No}</TableCell>
                    <TableCell>{e.Name}</TableCell>
                    <TableCell
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        gap: "1em",
                      }}
                    >
                      <TextField
                        id="outlined-number"
                        label="Antall etiketter"
                        type="number"
                        inputProps={{ min: "1" }}
                        value={e.prints}
                        onChange={(v) =>
                          handlePrintChange(v.target.value, index)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <IconButton
                        color="error"
                        onClick={() => removeProduct(index)}
                        tabIndex={-1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Portal;
